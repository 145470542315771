import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import './utils/styles/responsive-text.css';
import ComponentGallery from './pages/ComponentGallery';
import Main from "./pages/Main/Main";
import Home from "./pages/Home";
import IframePage from "./pages/Iframe";
import TagManager from 'react-gtm-module';
import ThankYouPage from "./pages/ThankYou/thankyou";

const landingPageVariants1 = [{
  endpoint: "s1",
  title: "Safety-first internet for kids",
  description: "Pathfinder removes bad content before kids even see it. It won't show up on YouTube, Google and other webpages."
}, {
  endpoint: "s2",
  title: "Keep your Kids safe Online",
  description: "Pathfinder filters unsafe content and guides kids to educational, age-appropriate resources."
}, {
  endpoint: "sm1",
  title: "Safe Browsing for your Kids",
  description: "Pathfinder removes bad content before kids even see it. It won't show up on YouTube, Google and other webpages."
}, {
  endpoint: "sh1",
  title: "Safety you can trust for your Child",
  description: "Give your kids the freedom to learn online without worrying about their safety. Pathfinder filters out bad content so they never see it."
}, {
  endpoint: "tm2",
  title: "Simplified Learning for Kids",
  description: "Pathfinder makes digitals tools simpler for kids to work on projects. We blur our distractions and highlight what's important in the moment they need help."
}, {
  endpoint: "tm3",
  title: "Project tools made easier for Elementary kids",
  description: "Pathfinder simplifies complex tools like Scratch or Canva so your kid can focus on learning and building their projects."
}, {
  endpoint: "tm4",
  title: "Project tools made easier for Middleschoolers",
  description: "Pathfinder simplifies complex tools like Scratch or Canva so your kid can focus on learning and building their projects."
}, {
  endpoint: "i1",
  title: "Tailored Internet for Kids",
  description: "Pathfinder adapts the internet to your child's pace, offering guided help as they explore the internet safely. "
}, {
  endpoint: "ih2",
  title: "A personalised Internet for your child",
  description: "Pathfinder provides personalized suggestions and on-screen help to support your child using the internet."
}, {
  endpoint: "ih3",
  title: "Turn your Kid's interests into Projects",
  description: "Pathfinder helps kids dive into project-based learning with easy-to-use tools."
}, {
  endpoint: "sh4",
  title: "Safe Search Engine for Homeschoolers",
  description: "Pathfinder removes bad content before kids even see it. It won't show up on YouTube, Google and other webpages."
}, {
  endpoint: "sm5",
  title: "Safe Search Engine for Microschools",
  description: "Pathfinder removes bad content before kids even see it. It won't show up on YouTube, Google and other webpages."
}, {
  endpoint: "ih5",
  title: "The Internet designed for Homeschoolers",
  description: "Pathfinder ensures safe, tailored learning for your child, adapted to your family values."
}, {
  endpoint: "im6",
  title: "The Internet designed for Microschools",
  description: "Pathfinder helps your learners navigate and explore the internet. It's a helper in their Chromebooks."
}];

const landingPagesHomeschoolingAndMicroschools = [
  {
    endpoint: "hm1",
    title: "Safe Search Engine for Microschools",
    description: "Give your learners freedom to explore and grow. Pathfinder filters innapropriate and low quality content from YouTube, Google and other websites so they can explore in a high quality environment."
  },
  {
    endpoint: "hm2",
    title: "A Safe Internet Space for Homeschoolers",
    description: "Pathfinder ensures your homeschoolers see only age-appropriate, family-values-aligned content. No more endless searches or worry about slop and inappropriate content."
  },
  {
    endpoint: "hm3",
    title: "Helping your child fulfil their potential",
    description: "Keep screen time wholesome and aligned with your faith. Use our safe browser and curated educational websites for real growth—without worry."
  }
];

const landingPagesMisc = [
  {
    endpoint: "misc1",
    title: "A Curated Internet for Alternative Learning",
    description: "Pathfinder offers a safe, child-led environment—perfect for families exploring Montessori, Waldorf, or unschooling methods."
  },
  {
    endpoint: "misc2",
    title: "Simplify Online Learning Projects",
    description: "Eliminate the overwhelm. Pathfinder highlights important tools (like Scratch or Canva) in the moment they're needed, guiding kids through online projects."
  },
  {
    endpoint: "misc3",
    title: "Holistic Growth, Not Just Grades",
    description: "Your child's creativity, curiosity, and independence flourish in a safe digital space—no intrusive ads, just meaningful content."
  }
];

const landingPagesInternetSafety = [
  {
    endpoint: "is1",
    title: "Safety-first internet for kids",
    description: "Pathfinder removes bad content before kids even see it. It won't show up on YouTube, Google and other webpages."
  },
  {
    endpoint: "is2", 
    title: "Worry-Free Internet for Families",
    description: "Pathfinder's 'safe browser' approach ensures no bad content slips through—letting your child focus on learning, not ads or scams."
  },
  {
    endpoint: "is3",
    title: "A Shield from Inappropriate Content", 
    description: "We remove explicit or harmful search results before they appear. Perfect for parents seeking a secure test browser or an everyday safe environment."
  }
];

const landingPagesDigitalLearning = [
  {
    endpoint: "dlt1",
    title: "Interactive Learning Platforms Made Simple",
    description: "Pathfinder integrates with top educational websites, AI tutors, and project-based tools—so your child can learn more, faster."
  },
  {
    endpoint: "dlt2",
    title: "Focus on Creation, Not Distractions",
    description: "We remove the clutter on sites. Kids get real digital literacy practice while staying on track with their projects."
  },
  {
    endpoint: "dlt3",
    title: "Empower Self-Directed Learners",
    description: "Pathfinder fosters problem-solving and creativity using curated digital tools—ideal for ages 8–15 in homeschool or microschool settings."
  },
  {
    endpoint: "dlt4",
    title: "A Holistic Approach to Online Education",
    description: "Not just drills or flashcards. Pathfinder connects children to deeper, hands-on project tools—making online learning truly engaging."
  }
];

const landingPagesParentalControls = [
  {
    endpoint: "pc1",
    title: "Your Parental Control, Their Freedom",
    description: "Pathfinder balances independence and safety. Set boundaries, track progress, and let them explore worry-free."
  },
  {
    endpoint: "pc2",
    title: "Worry-free Internet for your child",
    description: "Filter out harmful sites, manage screen time, and see real-time insights into your child's progress on projects or reading."
  },
  {
    endpoint: "pc3",
    title: "Monitor Without Hovering",
    description: "Receive updates on your child's online exploration. No more stress or constant checking—Pathfinder does the heavy lifting."
  }
];

const landingPagesPersonalizedLearning = [
  {
    endpoint: "ppl1",
    title: "Turn Interests into Projects",
    description: "Pathfinder helps kids dive deeper into topics they love—encouraging them to create, build, and showcase progress every step of the way."
  },
  {
    endpoint: "ppl2",
    title: "A Personalized Path for Every Child",
    description: "We adapt the internet to your child's pace, offering guided suggestions and easy on-screen help—perfect for self-directed learning."
  },
  {
    endpoint: "ppl3",
    title: "Build a Portfolio of Real Projects",
    description: "Give your child the tools to create, not just consume. From STEM projects to digital art, Pathfinder helps structure self-directed learning while building a showcase of actual accomplishments."
  },
];

const landingPagesMontessori = [
  {
    endpoint: "mont1",
    title: "Freedom Within Limits",
    description: "Create a thoughtfully prepared online environment where your 6-12 year old can develop digital skills naturally. Pathfinder removes distractions on sites like YouTube while preserving the freedom to explore purposefully."
  },
  {
    endpoint: "mont2",
    title: "Nurture Independence Online",
    description: "Balance screen time with meaningful activity. Pathfinder guides children toward creative tools and project-based learning, complementing their hands-on work while building essential digital literacy."
  },
  {
    endpoint: "mont3",
    title: "A Prepared Internet Environment",
    description: "Just like a Montessori classroom, the internet should support independence and excellence. Pathfinder creates an orderly digital space where children can work with purpose and develop real competencies."
  }
];

const landingPagesUnschoolers = [
  {
    endpoint: "uns1",
    title: "Worry-Free Browsing for Families",
    description: "Let your child explore freely while quietly filtering out ads and inappropriate content. No restrictions—just peace of mind that they won't stumble onto anything harmful."
  },
  {
    endpoint: "uns2",
    title: "Freedom Within Limits",
    description: "Support your child's natural curiosity online. Pathfinder removes distracting ads and unsafe content in the background. We replace it high quality content that interests them."
  },
  {
    endpoint: "uns3",
    title: "Your Eyes and Ears",
    description: "Give them the free to explore without worrying about ads and unsafe content. Pathfinder removes these in the background and replaces it with high quality content that interests them."
  },
];

const landingPagesYouTube = [
  {
    endpoint: "yt1",
    title: "Safe, High-Quality YouTube for Your Child",
    description: "Keep the good parts of YouTube while filtering out the concerning content. No more worrying about inappropriate videos or disturbing thumbnails sneaking through. Just quality content your child can enjoy safely exploring."
  },
  {
    endpoint: "yt2",
    title: "Freedom Within Limits",
    description: "Better than YouTube Kids, without the slop and garbage. Pathfinder automatically removes questionable content and replaces it with educational videos your child loves. YouTube can be excellence oriented rather than just a distraction."
  },
  {
    endpoint: "yt3",
    title: "Excellence Oriented YouTube",
    description: "Stop spending hours reviewing channels and curating playlists. Pathfinder automatically filters YouTube content in real-time, letting you relax knowing your child can discover great educational content without the risks."
  },
];

const landingPagesHomeschoolingConcierge = [
  {
    endpoint: "hc1",
    title: "Curated experiences for homeschool families",
    description: "Get an expert to do the research for you for free. Find the best class, field trip, apprenticeship, project, tools and more for your child's interest and pace."
  },
  {
    endpoint: "hc2",
    title: "Find the classes, apps, local activities for your curious child",
    description: "Get an expert to understand your kid's interests and do the research work for you. Receive an in-depth plan you can execute on and delight your kid."
  },
  {
    endpoint: "hc3",
    title: "ESA Funds personalized to your homeschooler",
    description: "An expert who is dedicated to your child's interests and growth. Receive a curated feed of learning experiences to help you find the best class, curriculum, tool, project, field trip and more."
  },
  {
    endpoint: "hc4",
    title: "Homeschool Scheduling and Research made simple",
    description: "An expert who is dedicated to your child's interests and growth. Receive a curated feed of learning experiences to help you find the best class, curriculum, tool, project, field trip and more."
  },
  {
    endpoint: "hc5",
    title: "All-In-One Homeschool Scheduling",
    description: "An expert who handles the scheduling and research for your homeschooling. Get personalized organization of classes, activities, and resources tailored to your child's unique interests and learning style."
  },
  {
    endpoint: "hc6",
    title: "Piecing together your ideal Homeschooling Schedule",
    description: "Homeschooling shouldn't be hard. But your child is unique and their needs are always changing. We get it. Pathfinder is an expert that constantly provides you with an all-in-one homeschooling schedule that helps your child maximally grow."
  },
  {
    endpoint: "hc7",
    title: "Piecing together the Dream Homeschooling Experience",
    description: "Homeschooling shouldn't be hard. But your child is unique and their needs are always changing. We get it. Pathfinder is an expert that constantly provides you with an all-in-one homeschooling schedule that helps your child maximally grow."
  }
];

const landingPageVariants = [...landingPageVariants1, ...landingPagesHomeschoolingAndMicroschools, ...landingPagesMisc, ...landingPagesInternetSafety, ...landingPagesDigitalLearning, ...landingPagesParentalControls, ...landingPagesPersonalizedLearning, ...landingPagesMontessori, ...landingPagesUnschoolers, ...landingPagesYouTube, ...landingPagesHomeschoolingConcierge];

function App() {
    useEffect(() => {
        // Initialize GTM
        TagManager.initialize({
            gtmId: 'GTM-PMWLTXNH'
        });
    }, []);

    return (
        <Router>
          <Routes>
              {process.env.NODE_ENV === 'development' && (
                  <Route path="/component-gallery" element={<ComponentGallery />} />

              )}
              {/* Map through landing page variants */}
              {landingPageVariants.map(({ endpoint, title, description }) => (
                  <Route 
                      key={endpoint}
                      path={`/${endpoint}`}
                      element={<Main title={title} description={description} imageBoolean={false} />}
                  />
              ))}

              {/* Root path now uses hc7 variant */}
              <Route path="/" element={<Main 
                title="Piecing together the Dream Homeschooling Experience" 
                description="Homeschooling shouldn't be hard. But your child is unique and their needs are always changing. We get it. Pathfinder is an expert that constantly provides you with an all-in-one homeschooling schedule that helps your child maximally grow." 
                imageBoolean={false}
                buttonText="Join our waitlist" 
              />} />
              <Route
                  path="/terms-and-conditions"
                  element={<IframePage pageURL={"f7ec797b20564a2c88f6ffce0f61409e"} />}
              />
              <Route
                  path="/privacy-policy"
                  element={<IframePage pageURL={"72dd48768286472b9ce8264aa97d7d26"} />}
              />
              <Route
                  path="/cookies-policy"
                  element={<IframePage pageURL={"27c4a08c23f9432f934f9fa3a9e6d26b"} />}
              />
              <Route
                  path="/thankyou"
                  element={<ThankYouPage />}
              />
          </Routes>
        </Router>
    );
}

export default App;
