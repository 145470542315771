import React, { useState, useRef } from 'react';
import Button from '../utils/components/grapes/atoms/button/Button';
import websiteCopy from '../data/websiteCopy.json';
import Projects from './Projects';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import emailjs from '@emailjs/browser';
import * as Icons from 'solar-icon-set';
// import TagManager from 'react-gtm-module';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import VisualSlider from './VisualSlider';
import heroBg from '../assets/images/bg-hero.png';
import VisualToggle from './VisualToggle';


interface HeroProps {
    title?: string;
    description?: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    images?: string[] | null;
    buttonText?: string;
}

interface FormDetails {
    from_name: string;
    from_email: string;
    kids_age: string;
    state: string;
}

const Hero: React.FC<HeroProps> = ({ title, description, showModal, setShowModal, images, buttonText }) => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        from_name: Yup.string().required("Name is required"),
        from_email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        kids_age: Yup.string().required("Age of kids is required"),
        state: Yup.string().required("State is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormDetails>({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    const sendEmail = (data: FormDetails) => {
        setIsSubmitting(true);
        
        // Get values from the form
        const formData = new FormData(form.current as HTMLFormElement);
        
        // Create a new form element for sending
        const sendForm = document.createElement('form');
        
        // Add the name and email fields
        sendForm.innerHTML = `
            <input name="from_name" value="${formData.get('from_name')}" />
            <input name="from_email" value="${formData.get('from_email')}" />
            <textarea name="message">Hi, I'm from ${formData.get('state')} and my kids are ages ${formData.get('kids_age')}. The landing page I saw was ${title} and the description was ${description}.</textarea>
        `;

        // Track form submission in Mixpanel
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Form Submitted', {
                endpoint: window.location.pathname.substring(1),
                title,
                description,
                name: data.from_name,
                email: data.from_email,
                kids_age: data.kids_age,
                state: data.state
            });
        }

        emailjs
            .sendForm(
                "service_lnffz7b",
                "template_8n44unm",
                sendForm,
                "1mrtCcpYjeR_RckcO"
            )
            .then(
                (result) => {
                    setIsSent(true);
                    setIsSubmitting(false);
                    setShowModal(false);
                    reset();
                    
                    // Track conversion using TagManager
                    // TagManager.dataLayer({
                    //     dataLayer: {
                    //         event: 'conversion',
                    //         'send_to': 'AW-16783651576/wC9qCOXIkO8ZEPjliMM-',
                    //         'value': 1.0,
                    //         'currency': 'USD'
                    //     }
                    // });
                    navigate('/thankyou');
                },
                (error) => {
                    setShowError(true);
                    setErrorMessage(error.text);
                }
            );
    };

    const handleSignUpClick = () => {
        // Track button click in Mixpanel
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Sign Up Button Clicked', {
                endpoint: window.location.pathname.substring(1),
                title,
                description
            });
        }

        // // Track button click conversion
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event: 'conversion',
        //         'send_to': 'AW-16783651576/hajwCIrRkO8ZEPjliMM-',
        //         'value': 1.0,
        //         'currency': 'USD'
        //     }
        // });
        
        // Open the modal
        setShowModal(true);
    };

    return (
        <div className={`w-full h-full bg-cover bg-center bg-no-repeat relative overflow-x-hidden`} style={{backgroundImage: `url(${heroBg})`}}>
            <div className={`text-center items-center justify-center ${!images ? 'h-screen mt-[-4rem] lg:mx-8 flex flex-col' : ''} relative`}>
                <div className={`${!images ? 'flex-1 flex flex-col justify-center' : ''} pt-16 md:pt-24 pb-10 md:py-16`}>
                    <h1 className={`${!images ? 'pb-16' : 'pb-12'} md:pb-6 text-display-medium md:text-display-large`}>
                            {"Piecing together your Dream Homeschooling Experience"}
                    </h1>
                    {/* <h2 className={`w-full max-w-[1000px] pt-4 text-heading-small mx-auto px-[24px]`}>
                        {"Homeschooling shouldn't be hard. But your child is unique and their needs are always changing. We get it. "}
                    </h2> */}
                    <h2 className={`${!images ? 'pb-14' : 'pb-10'} pt-4 w-full max-w-[1000px] text-heading-small mx-auto px-[24px]`}>
                        {"Pathfinder is an expert that constantly provides you with a values-aligned, all-in-one homeschooling schedule focussed on helping your child maximally grow."}
                    </h2>
                    <div className='mx-auto h-[40px] pb-2 w-[250px]' style={{ maxWidth: '1000px' }}>
                        <Button 
                            variant="primary"
                            size="lg"
                            onClick={handleSignUpClick}
                        >
                            {"Join our waitlist"}
                        </Button>
                    </div>
                </div>
                <div className='max-w-screen'>
                    {images && images.length >= 2 && (
                        <>
                            <div className="hidden md:flex max-w-screen justify-center w-full pb-20">
                                <VisualSlider 
                                    beforeImage={images[0]}
                                    afterImage={images[1]} 
                                    height={`${Math.min(800, Math.max(500, (window.innerWidth - 500)))}px`}
                                    />
                            </div>
                            <div className="block md:hidden flex max-w-screen justify-center w-full pb-20">
                                <VisualToggle 
                                    beforeImage={images[0]}
                                    afterImage={images[1]} 
                                    width={`${window.innerWidth - 40}px`}
                                    />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className={`absolute top-0 left-0 inset-0 z-50 flex items-center justify-center ${showModal ? 'visible' : 'invisible'}`}>
                <div className="fixed inset-0 bg-black opacity-50" onClick={() => setShowModal(false)}></div>
                <div className="bg-white rounded-md shadow-xmdl z-10 pt-6 px-6 max-w-2xl w-full mx-4">
                    <div className="flex justify-between items-center mt-[-0.5rem] mr-[-1rem] mb-2">
                        <h2 className="text-2xl font-bold">Sign Up - We're in private beta</h2>
                        <button className='cursor-pointer px-2 py-2'>
                            <Icons.CloseCircle 
                                iconStyle='Bold' 
                                size={24} 
                                onClick={() => setShowModal(false)} 
                            />
                        </button>
                    </div>
                    <div className="mt-[1rem] mb-6">
                        <form ref={form} onSubmit={handleSubmit(sendEmail)} className="flex flex-col gap-4">
                            {showError && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                    <span className="block sm:inline">{errorMessage}</span>
                                </div>
                            )}

                            <div>
                                <label htmlFor="from_name" className="block text-sm font-medium mb-1">Your name</label>
                                <input
                                    id="from_name"
                                    placeholder="Name"
                                    {...register("from_name")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.from_name && (
                                    <p className="text-red-500 text-sm">{errors.from_name.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="from_email" className="block text-sm font-medium mb-1">Your email</label>
                                <input
                                    id="from_email"
                                    type="email"
                                    placeholder="Email"
                                    {...register("from_email")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.from_email && (
                                    <p className="text-red-500 text-sm">{errors.from_email.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="kids_age" className="block text-sm font-medium mb-1">Your kids' ages</label>
                                <input
                                    id="kids_age"
                                    placeholder="e.g., 8, 10, 12"
                                    {...register("kids_age")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.kids_age && (
                                    <p className="text-red-500 text-sm">{errors.kids_age.message}</p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="state" className="block text-sm font-medium mb-1">State</label>
                                <input
                                    id="state"
                                    placeholder="e.g. Arizona"
                                    {...register("state")}
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pf-primary-T1"
                                />
                                {errors.state && (
                                    <p className="text-red-500 text-sm">{errors.state.message}</p>
                                )}
                            </div>

                            <div className="flex justify-end mt-4">
                                <Button
                                    variant="primary"
                                    size="sm"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;